<template>
  <el-menu v-bind="$attrs" text-color="black">
    <template v-for="(item, index) in menu" :key="index">
      <el-sub-menu
        v-if="item.items"
        :index="item.label"
        :key="item.label"
        popper-class="header-menu-popup"
      >
        <template #title>
          <div class="f-item">
            <span>{{ $t(`appMenu.${item.label}`) }}</span>
            <hr />
          </div>
        </template>
        <template v-for="(subItem, subIndex) in item.items" :key="subIndex">
          <el-sub-menu
            v-if="subItem.items"
            :index="subItem.label"
            :key="subItem.label"
            popper-class="header-menu-popup"
          >
            <template #title>
              <span>{{ $t(`appMenu.${subItem.label}`) }}</span>
            </template>
            <template
              v-for="(subItem2, subIndex2) in subItem.items"
              :key="subIndex2"
            >
              <el-menu-item :index="subItem2.path">
                <span>{{ $t(`appMenu.${subItem2.label}`) }}</span>
              </el-menu-item>
            </template>
          </el-sub-menu>
          <el-menu-item v-else :index="subItem.path">
            <span>{{ $t(`appMenu.${subItem.label}`) }}</span>
          </el-menu-item>
        </template>
      </el-sub-menu>
      <el-menu-item v-else :index="item.path">
        <div class="f-item">
          <span>{{ $t(`appMenu.${item.label}`) }}</span>
          <hr />
        </div>
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
export default {
  props: {
    menu: Array,
  },
};
</script>
<style lang="scss">
@import "../assets/base.scss";

.el-menu-main-pc {
  .f-item {
    @media (min-width: $md) {
      hr {
        display: none;
      }
    }
  }
}

.el-menu-main {
  --el-menu-hover-bg-color: white;
  height: calc(100vh - 76px); //TODO
  overflow-y: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  .f-item {
    display: block;
    width: 100%;
    span {
      padding-left: 8px;
      font-weight: 600;
      color: black;
    }
  }

  .el-sub-menu {
    .el-sub-menu__title,
    .el-menu-item {
      border-bottom: 1px solid white !important;
    }
  }

  .el-sub-menu .el-sub-menu__icon-arrow {
    font-size: 20px;
  }
  .el-sub-menu > .el-menu {
    background: rgba(24, 24, 24, 0.04);
  }
}

.header-menu-popup {
  border: none;
  .el-menu {
    --el-menu-bg-color: white !important;
    --el-menu-text-color: var(--wh-theme--bg-color) !important;
    --el-menu-active-color: var(--wh-theme--bg-color) !important;
    .el-sub-menu__title {
      background: white !important;
      color: var(--wh-theme--bg-color) !important;
    }
  }
}
</style>
