import axios from "axios";

// able to inherit latest setting of global axois
export class Service {
	constructor(private axiosOptions: Object) {
	}

	get axios() {
		//to inherit latest header settings
		const localAxios = axios.create({
			baseURL: "/api/v1", //default
			...this.axiosOptions
		});
		localAxios.interceptors.response.use((resp) => resp.data);
		return localAxios;
	}
}
