import CrudService from "../common/CrudService";
import { Service } from "@/common/Service";

//目前没有session,用这个来表示单次访问

class AccessLogService extends Service {
  log(tag: string) {
    console.log(tag);
    return this.axios({
      method: "POST",
      url: "/tag/",
      data: { tag },
    });
  }
}

class InquiryService extends CrudService {
  async calculatePrice(inquiry: any) {
    return this.axios({
      method: "POST",
      url: "/calculate-estimated-cost/",
      data: inquiry,
    });
  }
}

class OssService extends CrudService {
  async upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.axios({
      method: "POST",
      url: "/upload/",
      data: formData,
    });
  }
}

export default {
  brands: new CrudService({
    baseURL: "/api/v1/brands",
  }),
  settings: new CrudService({
    baseURL: "/api/v1/settings",
  }),
  pages: new CrudService({
    baseURL: "/api/v1/pages",
  }),
  accessLog: new AccessLogService({
    baseURL: "/api/v1/AccessLogs",
  }),
  subServices: new CrudService({
    baseURL: "/api/v1/subServices",
  }),
  inquiries: new InquiryService({
    baseURL: "/api/v1/inquiries",
  }),
  oss: new OssService({
    baseURL: "/api/v1/oss",
  }),
  qas: new CrudService({
    baseURL: "/api/v1/qas",
  }),
};

export function buildWatchesSearch(text: string) {
  return {
    search: {
      $or: [
        {
          nameEn: {
            $cont: text,
          },
        },
        {
          name: {
            $cont: text,
          },
        },
        {
          "brand.name": {
            $cont: text,
          },
          "brand.nameEn": {
            $cont: text,
          },
        },
        {
          keywords: {
            $cont: text,
          },
        },
      ],
    },
    join: [{ field: "brand", select: ["name", "nameEn"] }],
  };
}
