<template>
  <div class="home hidden-md-and-up mobile">
    <div class="relative" style="overflow: hidden; margin: auto">
      <img class="m-auto" src="/home/banner.webp" alt="" />
    </div>
    <div class="section" style="padding: 60px 30px">
      <div class="slogan">{{ $t("home.slogan") }}</div>
      <div class="w-full text-center">
        <el-button
          size="large"
          class="bg-white rounded-none section-btn"
          @click="$router.push({ name: 'inquiry' })"
          >{{ $t("home.getQuote") }}
        </el-button>
      </div>
    </div>
    <div class="section bg-gray-50">
      <div class="section-title">{{ $t("home.ourServices") }}</div>
      <div class="service">
        <img src="/home/service-full.webp" class="m-auto" alt="" />
        <div class="label">
          <span class="service-name">{{ $t("home.fullService") }}</span>
          <span class="service-price">{{ $t("home.startingFrom2000") }}</span>
        </div>
      </div>
      <div class="service">
        <img src="/home/service-refreshing.webp" class="m-auto" alt="" />
        <div class="label">
          <span class="service-name">{{ $t("home.caseRefinishing") }}</span>
          <span class="service-price">{{ $t("home.startingFrom1500") }}</span>
        </div>
      </div>
      <div class="service">
        <img src="/home/change-battery.jpg" class="m-auto" alt="" />
        <div class="label">
          <span class="service-name">{{ $t("home.batteryReplacement") }}</span>
          <span class="service-price">{{ $t("home.startingFrom300") }}</span>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-title">{{ $t("home.processIntroduction") }}</div>
      <div class="service-step flex">
        <img src="/home/yujian.webp" class="step-image" alt="" />
        <div class="step-content">
          <div class="step-title">{{ $t("home.preliminaryQuotation") }}</div>
          <div class="step-desc">{{ $t("home.preliminaryQuotationDesc") }}</div>
        </div>
      </div>
      <div class="service-step flex">
        <img src="/home/delivery.webp" class="step-image" alt="" />
        <div class="step-content">
          <div class="step-title">{{ $t("home.deliverWatch") }}</div>
          <div class="step-desc">{{ $t("home.deliverWatchDesc") }}</div>
        </div>
      </div>
      <div class="service-step flex">
        <img src="/home/repair.webp" class="step-image" alt="" />
        <div class="step-content">
          <div class="step-title">{{ $t("home.technicianRepair") }}</div>
          <div class="step-desc">{{ $t("home.technicianRepairDesc") }}</div>
        </div>
      </div>
      <div class="service-step flex">
        <img src="/home/confirm-step.webp" class="step-image" alt="" />
        <div class="step-content">
          <div class="step-title">{{ $t("home.acceptanceConfirmation") }}</div>
          <div class="step-desc">
            {{ $t("home.acceptanceConfirmationDesc") }}
          </div>
        </div>
      </div>
      <div class="w-full text-center mt-8">
        <el-button
          size="large"
          class="bg-white rounded-none section-btn"
          @click="$router.push({ name: 'inquiry' })"
          >{{ $t("home.experienceNow") }}</el-button
        >
      </div>
    </div>

    <div class="section bg-gray-50">
      <div class="section-title">常见问题</div>
      <div>
        <van-swipe :autoplay="3000" lazy-render class="pb-4">
          <van-swipe-item
            v-for="({ question, answer }, index) in QAs.filter(
              (s) => s.lang === (isEnglish ? 'EN' : 'ZH')
            )"
            :key="index"
          >
            <div class="qa">
              <div class="question">{{ question }}</div>
              <div class="answer">
                <img class="profile" src="/home/qiangshifu.webp" />
                {{ answer }}
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch, watchEffect } from "vue";

import "vue3-carousel/dist/carousel.css";
import useLoader from "@/common/Loader";
import api from "@/api";
import useSystemSettings from "@/common/UseSystemSettings";
import { useRouter } from "vue-router";
import { sortBy } from "lodash-es";

export default defineComponent({
  name: "HomeView",
  components: {},
  data: () => ({
    carouselSettings: {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
    },
  }),
  computed: {
    isEnglish() {
      return this.$i18n.locale === "en";
    },
  },

  setup() {
    const desktopSwipe = ref(null);
    const { systemSettings } = useSystemSettings();
    const QAs = ref([]);

    api.qas.find().then((data) => {
      QAs.value = data;
      console.log("data", data);
    });

    return {
      desktopSwipe,
      systemSettings,
      QAs,
    };
  },
});
</script>

<style lang="scss">
.van-swipe-item {
  min-height: 300px;
}

.home {
  overflow-x: hidden;

  .desc {
    width: 307px;
    margin: 40px auto;
    text-align: center;
    color: #272833;
    font-family: var(--title-font-family);
    font-size: 18px;
  }
  .slogan {
    margin: 0px auto 25px;
    font-size: 20px;
    text-align: center;
    color: #272833;
    font-family: var(--title-font-family);
  }
}

.home.hidden-sm-and-down {
  .articles {
    gap: 40px;

    .article {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      max-width: 33%;
    }
  }
}
</style>
