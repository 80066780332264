import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import api from "@/api";
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    //TODO
    return new Promise((resolve, reject) => {
      if (to.meta.keepScroll && savedPosition) {
        // create an Observer instance
        const resizeObserver = new ResizeObserver((entries) => {
          if (
            entries[0].target.clientHeight >=
            savedPosition.top + screen.height
          ) {
            resolve(savedPosition);
            resizeObserver.disconnect();
          }
        });
        // start observing a DOM node
        resizeObserver.observe(document.body);
      } else {
        resolve({ top: 0 });
      }
    });
  },
  routes: [
    {
      meta: {
        keepScroll: true,
      },
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/inquiry",
      name: "inquiry",
      component: () => import("../views/inquiryView.vue"),
    },
    {
      path: "/workflows",
      name: "workflows",
      component: () => import("../views/WorkflowsView.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/woohootime",
      name: "woohootime",
      meta: {
        externalUrl: "https://www.woohootime.com",
      },
      component: () => {},
    },
  ],
});

router.beforeResolve((to) => {
  api.accessLog.log(to.path);
});

router.beforeEach((to, from, next) => {
  // 检查路由的某个字段，比如 meta 中是否有指定的外部链接
  if (to.meta?.externalUrl) {
    // 如果有，使用 window.location.href 跳转到该外部链接
    // @ts-ignore
    window.location.href = to.meta.externalUrl;
  } else {
    // 如果没有外部链接，正常通过 next() 进行路由跳转
    next();
  }
});

export default router;
