import { createI18n } from "vue-i18n";

const locale = localStorage.getItem("lang") || "zh";
const i18n = createI18n({
  fallbackLocale: "zh",
  globalInjection: true,
  locale,
  messages: {
    en: {
      home: {
        slogan: "Trusted choice for watch repair",
        getQuote: "Get a quote",
        ourServices: "Our Services",
        fullService: "Full Service",
        caseRefinishing: "Case Refinishing",
        batteryReplacement: "Battery Replacement",
        startingFrom2000: "Starting from ¥2000",
        startingFrom1500: "Starting from ¥1500",
        startingFrom300: "Starting from ¥300",
        processIntroduction: "Workflow",
        preliminaryQuotation: "Quotation",
        deliverWatch: "Deliver",
        technicianRepair: "Repair",
        acceptanceConfirmation: "Confirmation",
        experienceNow: "Try Now",
        callUs: "Call Us",
        email: "Email",
        aboutUs: "About Us",
        learnAboutUs: "Learn About Us",
        aboutUsDetail:
          "Whether it's a priceless treasure or a basic entry-level piece, WatchFox Artisan™ is your trusted choice. In our studio, which boasts an artistic atmosphere and top-notch equipment, Khien Tran, a former Vacheron Constantin level-ten watchmaker with thirty-five years of experience, leads his team to provide artisan-level craftsmanship for your watch. With meticulous detail inspection, maintenance according to different brand standards, professional case refinishing experience and equipment, WatchFox Artisan™ will complete the service following the official brand process and offer up to twelve months of follow-up repair warranty.",
        preliminaryQuotationDesc:
          "Provide your watch's info for instant online quotes for various repair and maintenance services.",
        deliverWatchDesc:
          "Send your watch for pre-checking; we'll confirm services, prices, and time.",
        technicianRepairDesc:
          "Our technicians will performing services, adjustments, and inspections as per official procedures.",
        acceptanceConfirmationDesc:
          "Receive your serviced watch, meeting quality standards, and enjoy our maintenance service for the next twelve months.",
        aboutUsDesc:
          "Whether it's a priceless treasure or a basic entry-level piece, WatchFox Artisan™ is your trusted choice. <br /> <br /> In our studio, which boasts an artistic atmosphere and top-notch equipment, Khien Tran, a former Vacheron Constantin level-ten watchmaker with thirty-five years of experience, leads his team to provide artisan-level craftsmanship for your watch. <br /> <br /> With meticulous detail inspection, maintenance according to different brand standards, professional case refinishing experience and equipment, WatchFox Artisan™ will complete the service following the official brand process and offer up to twelve months of follow-up repair warranty.",
      },
      appMenu: {
        home: "Homepage",
        aboutUs: "About Us",
      },
      inquiry: {
        watchType: "Your watch type",
        serviceType: "Service required",
        watchBrand: "Your watch brand",
        viewAll: "View all",
        other: "Other",
        nextStep: "Next step",
        knowModel: "Do you know the watch model or series?",
        knowYear: "Do you know the year of manufacture of the watch?",
        modelOrSeries: "Your watch model or series",
        skip: "Skip",
        coveredFeatures: "Features covered by your watch",
        observedIssues: "Issues observed with the watch",
        needCaseRefinishing: "Do you need case refinishing service?",
        caseRefinishingInfo:
          "Case refinishing involves dealing with visible dents and scratches on the watch case. Technicians will polish and gold-fill as needed. This service is chargeable. The brightening service is included in the comprehensive maintenance by WatchFox, which we provide for free.",
        yes: "Yes",
        no: "No",
        knowWatchYear: "Do you know the year of manufacture of the watch?",
        before1980: "Before 1980",
        after1981: "After 1981",
        between1981And2000: "Between 1981 and 2000",
        after2000: "After 2000",
        submissionSuccess: "Submission successful?",
        submissionInfo:
          "We have received your information and will contact you within 48 hours. To learn more, please scan to add our customer service.",
        returnHome: "Return to home page",
      },
    },
    zh: {
      home: {
        contactUs: "联络我们",
        slogan: "钟表维修的安心首选",
        getQuote: "获取报价",
        ourServices: "我们的服务",
        fullService: "完全服务",
        caseRefinishing: "表壳翻新",
        batteryReplacement: "更换电池",
        startingFrom2000: "2000元起",
        startingFrom1500: "1500元起",
        startingFrom300: "300元起",
        processIntroduction: "检修流程",
        preliminaryQuotation: "预检报价",
        deliverWatch: "递送腕表",
        technicianRepair: "技师维修",
        acceptanceConfirmation: "验收确认",
        experienceNow: "立即体验",
        aboutUs: "关于我们",
        learnAboutUs: "了解我们",
        callUs: "联系我们：",
        email: "电子邮件：",
        aboutUsDetail:
          "无论是珍藏级的无价之宝，还是普通的入门作品，腕狐良工™都是您的安心首选。在我们具有艺术氛围和顶尖设备的工作室，具有三十五年经验的前江诗丹顿十级制表师Khien Tran 带领他的团队，将为您的腕表提供艺术家级的工匠服务。细腻入微的细节检测、遵循不同品牌标准的维修保养、专业表壳翻新经验和设备，腕狐良工™将全程以品牌官方的流程完成服务，并提供长达十二个月的后续维修保修。",
        preliminaryQuotationDesc:
          "仅需告知腕表的品牌型号与基本情况，我们将在线即时生成腕表维修保养各项服务的基础价格。",
        deliverWatchDesc:
          "将腕表邮寄或当面交予我们，技师将进行预检，与您确认腕表实际需要的服务项目、价格工期。",
        technicianRepairDesc:
          "我们的技师团队将遵循品牌各自标准，全程以品牌官方流程完成服务，并进行调校与检测。",
        acceptanceConfirmationDesc:
          "您将收到经过悉心服务且质检达标的心爱腕表，并在随后十二个月内享受我们的后续维保服务。",
        aboutUsDesc:
          "无论是珍藏级的无价之宝，还是普通的入门作品，腕狐良工™都是您的安心首选。 <br /> <br /> 在我们具有艺术氛围和顶尖设备的工作室，具有三十五年经验的前江诗丹顿十级制表师Khien Tran 带领他的团队，将为您的腕表提供艺术家级的工匠服务。<br /> <br /> 细腻入微的细节检测、遵循不同品牌标准的维修保养、专业表壳翻新经验和设备，腕狐良工™将全程以品牌官方的流程完成服务，并提供长达十二个月的后续维修保修。 ",
      },
      appMenu: {
        home: "主页",
        inquiry: "获取报价",
        workFlows: "检修流程",
        aboutUs: "关于我们",
        woohootime: "WooHoo Time 首页",
      },
      inquiry: {
        watchType: "您的腕表类型",
        serviceType: "您需要的服务",
        watchBrand: "您的腕表品牌",
        viewAll: "查看全部",
        other: "其他",
        nextStep: "下一步",
        knowModel: "您的腕表型号或系列",
        knowYear: "您是否知道腕表生产年份",
        modelOrSeries: "您的腕表型号或系列",
        skip: "跳过",
        coveredFeatures: "您的腕表涵盖哪些功能",
        observedIssues: "您观测到的问题",
        needCaseRefinishing: "表壳翻新服务",
        caseRefinishingInfo:
          "表壳翻新指腕表表壳有明显磕伤、划痕情况，技师将进行抛光、补金处理，此项目收费。如您需要我们将免费为您提供亮光服务。",
        yes: "是",
        no: "否",
        knowWatchYear: "您是否知道腕表生产年份??",
        before1980: "1980年前",
        after1981: "1981年后",
        between1981And2000: "1981-2000年间",
        after2000: "2000年后",
        submissionSuccess: "提交成功",
        submissionInfo:
          "您的信息已收到，我们将在48小时内与您联络。如想了解更多，欢迎扫码添加我们的客服。",
        returnHome: "返回首页",
      },
    },
  },
});

export function changeLanguage(locale: string) {
  i18n.global.locale = locale;
  document.body.classList.remove("en");
  document.body.classList.remove("zh");
  document.body.classList.add(locale);
  // @ts-ignore
  document.querySelector("html").setAttribute("lang", locale);
  localStorage.setItem("lang", locale);
}

changeLanguage(locale);

export default i18n;
