import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { Service } from "./Service";

export default class CrudService extends Service {
  //  `/api/v1/schedules/`
  findOne(id: any) {
    const data = this.axios({
      url: `/${id}`,
      method: "GET",
    });

    return data;
  }

  async find(options = {}) {
    const query = RequestQueryBuilder.create(options).query();
    const data = await this.axios({
      url: query ? `/?${query}` : "",
      method: "GET",
    });
    return data;
  }

  create(data: any) {
    return this.axios({
      method: "POST",
      data: data,
    });
  }

  update(data: any) {
    return this.axios({
      url: data.id,
      method: "PATCH",
      data: data,
    });
  }

  remove(id: any) {
    return this.axios({
      url: id,
      method: "DELETE",
    });
  }

  createMany(data: any) {
    return this.axios({
      url: "bulk",
      method: "POST",
      data: {
        bulk: data,
      },
    });
  }
}
