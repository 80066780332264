import exp from "constants";
import {ref, watch} from "vue";

const isMobile = ref(true);

function detectScreenSize(){
    let screenWidth = screen.width;
    let viewport:any = document.querySelector("meta[name=viewport]");
    if(screenWidth <= 450){
        viewport.setAttribute('content', "width=375px,user-scalable=no");
    }else{
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    }
}

function detect(){
        const viewportWidth = window.innerWidth;
        isMobile.value = (viewportWidth <= 992);
}

detectScreenSize();
detect();
window.addEventListener('resize',detectScreenSize);
window.addEventListener('resize',detect);

export default function useDevice(){
    return { isMobile };
}

