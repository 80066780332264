import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import router from './router'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/dist/index.css'
import Vue3TouchEvents from "vue3-touch-events";
import { createI18n } from 'vue-i18n'
import i18n from "@/common/i18n";
import App from './App.vue'
import './assets/index.css'

const app = createApp(App)

app.use(router)
app.use(Vue3TouchEvents);

app.use(i18n)

app.mount('#app')



