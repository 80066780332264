<template>
  <div v-if="isMobile" class="common-layout hidden-md-and-up">
    <el-container>
      <el-header v-if="!$route.meta.hideHeader">
        <div class="flex h-8 mt-5" @click="$router.push('/')">
          <div class="flex-1">
            <el-button type="text" @click.stop="drawer = !drawer">
              <img
                v-if="drawer"
                src="/public/home/Icons_icon-long-arrow-left.svg"
                alt=""
              />
              <img v-else src="/public/home/Icons_icon-menu.png" alt="" />
            </el-button>
          </div>
          <div class="flex-none title justify-self-center logo">
            <img
              src="/public/home/woohoowatchcare.png
"
              alt=""
            />
          </div>
          <div class="flex-1 text-right">
            <!--                      <el-button-->
            <!--                        type="text"-->
            <!--                        class="inline-block"-->
            <!--                        @click.stop="changeLanguage"-->
            <!--                      >-->
            <!--                        <img-->
            <!--                          v-if="isEnglish"-->
            <!--                          src="/public/home/icon-long-arrow-Chinese.png"-->
            <!--                          style="height: 24px"-->
            <!--                          alt=""-->
            <!--                        />-->
            <!--                        <img-->
            <!--                          v-else-->
            <!--                          src="/public/home/icon-long-arrow-English.png"-->
            <!--                          style="height: 24px"-->
            <!--                          alt=""-->
            <!--                        />-->
            <!--                      </el-button>-->
          </div>
        </div>
      </el-header>
      <el-main class="layout-main">
        <el-container class="main-container">
          <el-drawer
            :show-close="false"
            :with-header="false"
            v-model="drawer"
            size="75%"
            direction="ltr"
            modal-class="drawer-overlay"
          >
            <app-menu
              v-if="drawer"
              class="el-menu-main"
              default-active="2"
              :menu="menu"
              @select="selectView"
            >
            </app-menu>
          </el-drawer>
          <el-main>
            <RouterView :key="$route.path" />
          </el-main>
          <el-footer>
            <div class="contact-us">{{ $t("home.contactUs") }}</div>
            <div class="qrcodes">
              <div class="qrcode">
                <img src="/public/home/contact-qr.jpg" />
                <div class="label">微信客服</div>
              </div>
            </div>
            <div class="desc">
              线下地址：上海市静安区延安中路 740 弄 14 号 3 楼
              <br />
              工作时间：周一至周日（法定节假日除外）10:00-18:00
              <br />
            </div>
            <div class="info">
              {{ $t("home.callUs") }}
              <span>+86 133 9142 9557</span>
              <br />
              {{ $t("home.email") }}
              <a href="mailto:watchcare@woohootime.com"
                >watchcare@woohootime.com</a
              >
            </div>

            <div class="contact-us mt-8">友情链接</div>
            <div class="links">
              <el-button type="text">
                <a
                  href="https://www.xiaohongshu.com/user/profile/6163e4f60000000002021c46?xhsshare=CopyLink&appuid=59fc75a211be10361dead159&apptime=1709187482"
                  ><img src="/public/home/red-book-logo.webp" alt=""
                /></a>
              </el-button>
              <el-button type="text">
                <a href="https://www.woohootime.com"
                  ><img
                    style="padding: 5px 0"
                    src="/public/home/woohootime-logo.webp"
                    alt=""
                /></a>
              </el-button>
            </div>

            <div class="terms-link">
              <a
                class="float-right"
                href="https://beian.miit.gov.cn"
                target="_blank"
                >沪ICP备2024047502号-1</a
              >
            </div>
            <div class="copy-right">@Copyright 腕狐良工</div>
          </el-footer>
        </el-container>
      </el-main>
    </el-container>
  </div>
  <div
    v-else
    class="common-layout hidden-sm-and-down"
    style="margin: 100px auto; width: 400px; text-align: center"
  >
    <h1 class="mb-10" style="font-size: 24px">即将上线!</h1>
    <p>请用手机进行浏览，PC端正在建设中。</p>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { watch, ref } from "vue";
import AppMenu from "./components/app-menu.vue";
import { Close as CloseIcon } from "@element-plus/icons-vue";
import useLoader from "@/common/Loader";
import api, { buildWatchesSearch } from "@/api";
import { debounce } from "lodash";
import { changeLanguage } from "@/common/i18n";
import useDevice from "@/common/device";

const drawer = ref(false);
const searchContent = ref("");
const searchResults = ref([]);

const menu = ref([
  { label: "home", path: "/" },
  { label: "inquiry", path: "/inquiry" },
  { label: "workFlows", path: "/workflows" },
  {
    label: "aboutUs",
    path: "/about",
  },
  {
    label: "woohootime",
    path: "/woohootime",
  },
]);

export default {
  components: {
    AppMenu,
    CloseIcon,
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === "en";
    },
  },
  methods: {
    selectView(path) {
      drawer.value = false;
      this.$router.push(path || "/");
    },
    changeLanguage() {
      if (this.isEnglish) {
        changeLanguage("zh");
      } else {
        changeLanguage("en");
      }
    },
    gotoSearchResult(id) {
      this.searchDrawer = false;
      this.$router.replace("/watches/" + id);
    },
  },
  setup() {
    const router = useRouter();
    const { loading: searchLoading, asyncLoad: asyncLoadSearch } = useLoader();
    const searchDrawer = ref(false);
    const { isMobile } = useDevice();

    watch(
      searchContent,
      debounce((val) => {
        if (val) {
          const { search, join } = buildWatchesSearch(val) || {};
          asyncLoadSearch(
            api.watches
              .find({
                search: {
                  $and: [
                    {
                      published: { $eq: true },
                    },
                    search,
                  ],
                },
                join,
                limit: 4,
              })
              .then((data) => {
                searchResults.value = data;
              })
          );
        }
      }, 100)
    );

    function moreSearch() {
      router.push(`/search?search=${searchContent.value}`);
      searchDrawer.value = false;
    }

    return {
      menu,
      drawer,
      searchDrawer,
      searchContent,
      searchResults,
      searchLoading,
      asyncLoadSearch,
      moreSearch,
      isMobile,
    };
  },
};
</script>
<style lang="scss">
@import "./assets/base.scss";

.hidden-sm-and-down.common-layout {
  --el-header-height: 108px !important;
  .el-header {
    --el-header-height: 108px !important;
    overflow-y: hidden;
    padding: 0 30px;
  }

  .el-main {
    --el-header-height: 108px !important;
    min-height: calc(100vh - 108px);
    .search-header {
      .search-box-wrap {
        max-width: 1330px;
        margin: auto;
      }

      .search-box.el-input {
        width: calc(100% - 80px);
        margin: 8px 40px;
      }
    }
    .searchResult {
      max-width: 1330px;
      margin: auto;
      .watches {
        padding: 40px;
      }
    }
    .view {
      width: 100%;
      max-width: 1330px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .el-footer {
    padding: 60px 40px 40px;
    background: center url(/public/home/footer-pc.jpg);

    .qrcodes {
      padding-top: 40px;
    }

    .terms-link {
      margin-top: 30px;
    }
  }
}

.el-header {
  --el-header-height: 76px !important;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 9;

  .title {
    font-size: 13px;
    color: white;
    padding: 8px;
    text-align: center;
  }

  .logo {
    width: 200px;

    img {
      height: 20px;
      margin: auto;
    }
  }

  .el-button {
    width: 30px;
  }

  .el-button + .el-button {
    margin-left: 4px;
  }

  .el-button--text {
    padding: 0;
  }
}

.el-header + .layout-main {
  margin-top: var(--el-header-height);
}

.el-main {
  --el-header-height: 76px !important;
  --el-main-padding: 0 !important;
  min-height: calc(100vh - 76px - 360px);

  .main-container {
    .el-drawer {
      --el-drawer-padding-primary: 0;
      font-family: var(--title-font-family);
    }

    .drawer-overlay {
      position: fixed;
      top: var(--el-header-height);
      overflow-y: auto;
      height: calc(100vh - 76px);
    }

    .search-header {
      background: var(--wh-theme--bg-color) !important;
      margin-top: -1px;
      color: white;

      .search-box.el-input {
        --el-input-icon-color: white;
        --el-text-color-placeholder: rgba(255, 255, 255, 0.25);
        --el-input-text-color: white;
        width: calc(100% - 60px);
        margin: 8px 30px;
        font-size: 14px;
        line-height: 20px;

        .el-input__wrapper {
          border-width: 0 0 1px 0;
          border-bottom: 1px solid white;
          border-radius: 0;
          box-shadow: none;
          padding: 0;
          margin: -1px;
          background: inherit;
        }

        .el-input__suffix {
          font-size: 24px;
        }
      }
    }

    .searchResult .watches {
      padding: 20px;

      .el-row {
        margin: 0;
      }
    }
  }
}

.common-layout .el-footer {
  color: black;
  --el-footer-height: auto;
  padding: 30px;
  font-weight: 400;
  text-align: left;

  a {
    text-decoration: underline;
  }

  .contact-us {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.28px;
  }

  .desc {
    margin-top: 15px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.17px;
  }

  .info {
    margin-top: 16px;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.17px;
  }

  .links {
    margin-top: 8px;
    img {
      height: 20px;
    }
  }

  .terms-link {
    margin-top: 16px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.17px;
  }

  .copy-right {
    margin-top: 16px;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  .qrcodes {
    padding-top: 20px;

    .qrcode {
      display: inline-block;

      img {
        width: 68px;
      }

      .label {
        text-align: center;
        font-size: 10px;
        padding-top: 6px;
      }
    }

    .qrcode + .qrcode {
      margin-left: 20px;
    }
  }
}
</style>
