import { computed, ref } from "vue";
import { ElMessage } from "element-plus";

export default function useLoader() {
	const counter = ref(0);

	function startLoad() {
		counter.value += 1;
	}
	function cancelLoad() {
		counter.value -= 1;
	}

	function asyncLoad(p:any) {
		if (typeof p === "function") {
			p = p();
		}
		// avoid computed watch this property
		setTimeout(() => {
			counter.value += 1;
		});
		// TODO handle error?
		return Promise.resolve(p)
			.catch((e) => {
				//e?.response?.data?.message?.join("<br/>")
				ElMessage.error(e.message);
				throw e;
			})
			.finally(() => {
				setTimeout(() => {
					counter.value -= 1;
				});
			});
	}

	const loading = computed(() => {
		return counter.value > 0;
	});

	return {
		startLoad,
		cancelLoad,
		asyncLoad,
		loading
	};
}
