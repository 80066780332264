import api from "@/api";
import { ref } from "vue";

const systemSettings = ref({});
let loaded = false;

export default function useSystemSettings() {
  if (!loaded) {
    loaded = true;
    // api.settings.findOne('system_settings').then(({value} :any)=>{
    //     systemSettings.value = value;
    // })
    //TODO
  }

  return {
    systemSettings,
  };
}
